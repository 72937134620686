.navigation {
  &__container {
    display: flex;
    padding-left: 12px;
    align-items: center;
    font-size: 14px;

    &.isFetching {
      .navigation-arrow,
      .navigation__month,
      .btn {
        pointer-events: none;
      }
    }
  }
}
