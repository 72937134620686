.body-products {
  position: relative;
  display: flex;
  min-width: 1175px;
  overflow: hidden;

  table {
    display: block;
    border: none;
    border-spacing: 0;
    max-width: calc(100% - 154px);

    tbody {
      display: block;
    }
  }

  &__labelList {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    text-decoration: none;
    overflow: hidden;
  }

  &__itemContent {
    margin: 3px 4px 3px 0;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    width: 150px;
    height: 42px;
    background-color: #124559;
    color: #fff;
    text-decoration: none;

    & span {
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.15;
      font-size: 15px;
    }
  }

  &__itemCells {
    margin: 0 0 0 4px;
    padding: 0;
    display: flex;
    list-style: none;
    border-left: 1px solid #e0e0e0;
    overflow: hidden;
  }

  &__out-of-range {
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background-color: #f3f3f3;
    z-index: 0;
  }

  &__cell {
    position: relative;
    margin: 0;
    padding: 0;
    width: 48px;
    height: 48px;

    .empty-block,
    .empty-selected-block {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &.cell-being-selected {
      .empty-block {
        background-color: red;
        z-index: -1;
      }
    }

    &.cell-being-start-selected {
      .empty-block {
        right: 0;
        width: 50%;
        background-color: red;
        z-index: -1;
      }
    }

    &.cell-being-end-selected {
      .empty-end-block {
        position: absolute;
        left: 50%;
        width: 100%;
        height: 100%;
        background-color: red;
        z-index: -1;
      }
    }

    &.cell-being-deselected {
      .empty-block {
        background-color: #fff;
        z-index: -1;
      }
      .empty-end-block {
        background-color: #fff;
        z-index: 0;
      }
    }

    &.cell-selected {
      .empty-selected-block {
        background-color: blue;
        z-index: -1;
      }
    }

    &.cell-first-selected {
      .empty-selected-block {
        right: 0;
        width: 50%;
        background-color: blue;
        z-index: -1;
      }
    }

    &.cell-last-selected {
      .empty-selected-end-block {
        position: absolute;
        left: 50%;
        width: 100%;
        height: 100%;
        background-color: blue;
        z-index: -1;
      }
    }

    &.cell-start-month-selected {
      .empty-selected-month-block {
        position: absolute;
        left: 0;
        width: 50%;
        height: 100%;
        background-color: blue;
        z-index: -1;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      background: #e0e0e0;
      z-index: 1;
    }

    &::before {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      z-index: 2;
    }

    &::after {
      top: 0;
      right: 0px;
      width: 1px;
      height: 100%;
    }
  }

  &__event {
    position: relative;
    display: block;
    height: 100%;
    background-color: #e13e55;
    z-index: 1;

    &.highlight {
      background-color: #ed8d9b !important;
    }

    &.isAdmin {
      background-color: #5a889b;
      &.highlight {
        background-color: #a1bbc6 !important;
      }
    }
  }
}
