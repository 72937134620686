.navigation {
  &__wrapper {
    margin: 0 12px;
    display: flex;
    align-items: center;
  }

  &__monthList {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
  }

  &__month {
    margin: 0 2px;
    padding: 6px 12px;
    cursor: pointer;
  }
}
